.rotation-control {
    position: absolute;
	bottom: $spacing__outer-padding;
	right: $spacing__outer-padding;

	@media (max-width: $responsive__max-width-mobile) {
		bottom: $spacing__outer-padding-mobile;
		right: $spacing__outer-padding-mobile;
	}

	.region {
		background-color: rgba($color__white, 0.4);
        border: 4px solid rgba($color__white, 0.99);
        border-radius: 50%;
		bottom: 0;
		width: 120px;
		height: 120px;
	}

	.handle {
		background-color: rgba($color__white, 0.99);
		border-radius: 50%;
		position: absolute;
		bottom: 0;
		width: 40px;
        height: 40px;
        opacity: 1;
        transition: opacity 0.15s ease-in-out;
        -webkit-user-select: none;
        user-select: none;
	}
}

.movement-control {
    position: absolute;
    bottom: $spacing__outer-padding;
	left: $spacing__outer-padding;

	@media (max-width: $responsive__max-width-mobile) {
		bottom: $spacing__outer-padding-mobile;
		left: $spacing__outer-padding-mobile;
	}

	div {
		background-color: rgba($color__white, 0.4);
		border: 4px solid $color__white;
		position: absolute;
		border-radius: 10px;
		height: 50px;
		width: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		svg {
			fill: $color__white;
		}

		&.left {
			left: 0;
			bottom: 0;
		}

		&.right {
			left: 140px;
			bottom: 0;
		}

		&.backward {
			left: 70px;
			bottom: 0;
		}

		&.forward {
			left: 70px;
			bottom: 70px;
		}
	}
}