.artist-overlay {
    background-color: rgba($color__white, 0.99);
    position: fixed;
    width: 100%;
    top: 80px;
    bottom: 0;
    z-index: 1;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 75px $spacing__outer-padding $spacing__outer-padding $spacing__outer-padding;

    @media(max-width: $responsive__max-width-mobile) {
        top: 70px;
        padding: $spacing__outer-padding-mobile;
    }

    h2 {
        margin-top: 0;
    }

    .about {
        margin-bottom: 100px;
    }
}