.credits-overlay {
	background-color: rgba($color__white, .95);
	position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
	padding: 155px $spacing__outer-padding 75px $spacing__outer-padding;
	overflow-y: auto;
	cursor: pointer;
	z-index: 500;

	@media (max-width: $responsive__max-width-mobile) {
	  padding: 155px $spacing__outer-padding-mobile 75px $spacing__outer-padding-mobile;
	}

	h3 {
	  margin-top: 0;
	  margin-bottom: 75px;
	}

	.credit-item {
	  display: flex;
	  align-items: flex-start;
	  font-size: $font__size-small;
	  margin-bottom: 50px;

	  @media (max-width: $responsive__max-width-mobile) {
		flex-direction: column;
	  }

	  &:last-child {
		margin-bottom: 0;
	  }

	  img {
		margin-right: $spacing__column-gap;
		width: 100px;
		height: auto;

		@media (max-width: $responsive__max-width-mobile) {
		  margin-bottom: $spacing__column-gap;
		}
	  }

	  p {
		max-width: 570px;
		margin-top: 0;
	  }
	}
  }