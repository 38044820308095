.list-view {
    background-color: $color__white;
    box-sizing: border-box;
    min-height: 100vh;
    height: 100vh;
    overflow-y: auto;
    padding: 155px $spacing__outer-padding $spacing__outer-padding $spacing__outer-padding;

    @media(max-width: $responsive__max-width-mobile) {
        padding: 155px $spacing__outer-padding-mobile;
    }

    .artist-list-item {
        margin-bottom: 10px;
        clear: both;

        h2 {
            a {
                color: $color__black;
            }
        }

        &:first-of-type h2 {
            margin-top: 0;
        }

        &:first-child h1 {
            margin-top: 0;
        }

        h3, .h4 {
            margin-bottom: 0;
            margin-top: 28px;
        }

        /*&:nth-child(even) {
            background-color: $color__gray;
            margin: 0 -#{$spacing__outer-padding};
            padding: 0 $spacing__outer-padding;

            @media(max-width: $responsive__max-width-mobile) {
                margin: 0 -#{$spacing__outer-padding-mobile};
                padding: 0 $spacing__outer-padding-mobile;
            }

            h2 {
                padding-top: 28px;
            }
        }*/
    }

    .pagination {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        clear: both;
    }
}