.intro-overlay {
    z-index: 1000;
    position: absolute;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color__black, 0.75);

    .inner {
        background-color: $color__white;
        position: relative;
        max-width: 580px;
        min-height: 600px;
        text-align: center;

        @media(max-width: $responsive__max-width-mobile) {
            max-width: 100%;
            min-height: 475px;
        }

        h4 {
            margin-top: 55px;
        }

        .close-dialog {
            border: 0;
            padding: 0;
            width: 28px;
            position: absolute;
            cursor: pointer;
            top: 28px;
            right: 28px;
            z-index: 10;
        }

        .swiper-container {
            position: unset;
        }

        .swiper-slide {
            box-sizing: border-box;
            padding: 28px;
        }

        .swiper-pagination-bullet-active {
            background: $color__black;
        }

        .swiper-pagination-bullets {
            bottom: 30px;
        }

        .swiper-button-prev, 
        .swiper-button-next {
            bottom: 28px;
            height: 27px;
            z-index: 15;
            top: unset;

            &:after {
                content: '';
            }
        }

        .swiper-button-prev {
            left: 28px;
            background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"%3E%3Cpath d="M25,10.9H6l8.7-8.7L12.5,0L0,12.5L12.4,25l2.3-2.2L6,14.1h19V10.9z"/%3E%3C/svg%3E') center no-repeat;
        }

        .swiper-button-next {
            right: 28px;
            background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"%3E%3Cpath d="M5.977,13.852H16.608l-4.9,4.9,1.269,1.225,7-7-7-7L11.752,7.2l4.856,4.9H5.977Z" transform="translate(-5.977 -5.977)"/%3E%3C/svg%3E') center no-repeat;
        }
    }
}