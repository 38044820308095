
@import "../../node_modules/kunstmuseum-components/src/style.scss";
@import "./javascript-disabled";

html, body {
	width: 100%;
	height: 100%;
	background-color: $color__black;
}

body {
	margin: 0px;
	padding: 0px;
	overflow: hidden;
}

canvas {
	width: 100%;
	height: 100%;
}

#nprogress {
	.bar {
		background: $color__black;
	}

	.peg {
		box-shadow: 0 0 10px $color__black, 0 0 5px $color__black;
	}
}

ul.menu li {
	a:hover svg {
		stroke: none;
		fill: $color__link;
	}

	&:last-child {
		a:hover svg {
			fill: none;
			stroke: $color__link;
		}
	}
}

.scene-holder {
	-webkit-user-select: none;  
	-moz-user-select: none;    
	-ms-user-select: none;      
	user-select: none;
}

.has-gray-background {
	background-color: $color__gray;
}

@keyframes loadingPulse {
	50% {
		opacity: .75;
	}
	to {
		opacity: 1;
	}
}

.placeholder {
	animation: loadingPulse 1s ease-in-out infinite;
}

.artworks-grid {
	display: grid;
  	grid-template-columns: 1fr 1fr 1fr 1fr;
	column-gap: $spacing__column-gap;
	row-gap: 65px;

	@media (max-width: $responsive__max-width-tablet) {
		grid-template-columns: 1fr 1fr;
	}

	@media (max-width: $responsive__max-width-mobile) {
		grid-template-columns: 1fr;
	}

	.artwork {		
		h4 {
			margin: 0;
			font-weight: normal;
			color: $color__black;
		}
	 
		img {
			width: 100%;
			margin-bottom: 20px;
		}

		.placeholder {
			background-color: $color__gray;
			margin-bottom: 20px;
			aspect-ratio: 4 / 3;
		}
	}
}

.keyboard-hints {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	bottom: $spacing__outer-padding;
	right: $spacing__outer-padding;

	.hint {
		display: flex;
		align-items: center;
		color: $color__white;
		margin-top: 25px;

		.icon {
			margin-left: 15px;
			background-color: rgba($color__white, 0.4);
			border: 4px solid $color__white;
			border-radius: 10px;
			height: 50px;
			width: 50px;
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				fill: $color__white;
			}
		}
	}
}

.selection-indicator {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 100%;
	border: 4px solid $color__white;
	transition: all ease-in-out .3s;
	width: 20px;
	height: 20px;
	opacity: 1;

	&.enabled {
		width: 300px;
		height: 300px;
		opacity: 0;
	}
}

.flag {
	width: 30px;
	height: 24px;
}

.CookieConsent {
    box-sizing: border-box;
    padding: 15px $spacing__outer-padding;

	@media(max-width: $responsive__max-width-mobile) {
		padding: 15px $spacing__outer-padding-mobile;
    }

	a.button {
		display: unset;
	}
}
@import "./search-overlay";
@import "./detail-overlay";
@import "./artist-overlay";
@import "./welcome-page";
@import "./intro-overlay";
@import "./touch-controls";
@import "./list-view";