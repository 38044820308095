noscript {
    position: fixed;
    z-index: 1000;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    background-color: $color__black;
    color: $color__white;
    padding: $spacing__outer-padding;

    @media(max-width: $responsive__max-width-mobile) {
        padding: $spacing__outer-padding-mobile;
    }
}