.search-overlay {
    background-color: rgba($color__black, 0.75);
    position: absolute;
    max-height: 100vh;
    overflow-y: auto;
    z-index: 900;
    cursor: pointer;
    width: 100%;
    bottom: 0;
    top: 0;

    .content {
        background-color: rgba($color__white, 0.99);
        padding: $spacing__outer-padding;
        cursor: default;

        @media(max-width: $responsive__max-width-mobile) {
            padding: $spacing__outer-padding-mobile;
        }

        form {
            position: relative;
            max-width: 545px;
            margin-top: 80px;

            input {
                width: 100%;
                box-sizing: border-box;
                padding-right: 32px;
            }

            button {
                border: 0;
                padding: 0;
                margin: 0;
                position: absolute;
                bottom: 9px;
                right: 0;

                svg {
                    stroke: #AFAFB9;

                    &:hover {
                        stroke: $color__black;
                    }
                }
            }
        }

        .search-result {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            max-width: 66.666%;
            margin-bottom: 10px;

            @media (max-width: $responsive__max-width-tablet) {
                max-width: 100%;
            }

            &:first-child {
                margin-top: 15px;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .search-result-type {
                font-style: italic;
                margin-left: 25px;
            }
        }
    }
}