.detail-overlay {
    background-color: rgba($color__white, 0.99);
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    z-index: 800;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 155px $spacing__outer-padding $spacing__outer-padding $spacing__outer-padding;

    @media(max-width: $responsive__max-width-mobile) {
        padding: $spacing__outer-padding-mobile + 70px $spacing__outer-padding-mobile $spacing__outer-padding-mobile $spacing__outer-padding-mobile;
    }

    .placeholder {
        background-color: $color__gray;
    }

    .title {
        margin-top: 0;
        margin-bottom: 100px;

        .placeholder {
            width: 100%;
            height: 70px;
            margin-bottom: 25px;
        }
    }

    .image {
        display: flex;
        gap: $spacing__column-gap;

        @media(max-width: $responsive__max-width-tablet) {
            flex-direction: column;
        }

        .main-image {
            position: relative;
            max-width: 66.66%;
            cursor: pointer;

            @media(max-width: $responsive__max-width-tablet) {
                max-width: 100%;
            }

            img {
                max-height: calc(100vh - 80px);
                max-width: 100%;
            }

            .lightbox-badge {
                position: absolute;
                right: 25px;
                top: 25px;
                background-color: $color__black;
                color: $color__white;
                border-radius: 50px;
                padding: 10px;
                width: 30px;
                height: 30px;
                line-height: 26px;
                text-align: center;

                svg {
                    stroke: $color__white;
                    margin-top: 3px;
                }
            }
        
            .placeholder {
                min-width: 55vw;
                height: 700px;
            }
        }

        &.portrait .main-image img {
            max-height: calc(100vh - 80px);
            max-width: unset;

            @media(max-width: $responsive__max-width-tablet) {
                max-width: 100%;
            }
        }

        .details {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            @media(max-width: $responsive__max-width-tablet) {
                flex-direction: column-reverse;

                .content {
                    margin-bottom: 50px;
                }
            }

            .placeholder {
                width: 75%;
                height: 130px;
            }
        }
    }

    .description {
        margin: 0 -#{$spacing__outer-padding};
        padding: 0 $spacing__outer-padding 125px $spacing__outer-padding;

        @media(max-width: $responsive__max-width-mobile) {
            margin: 0 -#{$spacing__outer-padding-mobile};
            padding: 0 $spacing__outer-padding-mobile 125px $spacing__outer-padding-mobile;
        }

        h2 {
            padding-top: 28px;
        }
        
        .long-description,
        .show-more-button {
            margin-top: 40px;
        }

        .short-description,
        .long-description {
            max-width: 66.666%;

            @media(max-width: $responsive__max-width-tablet) {
                max-width: 100%;
            }
        }
    }

    .credits {
        font-size: $font__size-small;
    }

    .lightbox {   
        background-color: rgba($color__black, 0.99);
        position: fixed;
        margin-top: 80px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-content: center;

        @media(max-width: $responsive__max-width-mobile) {
            margin-top: 70px;
        }

        .swiper-container {
            width: 100%;
        }

        .swiper-slide {
            display: flex;
            justify-content: center;
            align-content: center;
            width: 100%;

            img {
                max-height: calc(100vh - (80px * 2));
                margin: $spacing__outer-padding 0;
                max-width: 100%;
                height: auto;
            }
        }

        .swiper-button-prev {
            color: $color__white;
            left: $spacing__outer-padding;
            background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25"%3E%3Cpath d="M25,10.9H6l8.7-8.7L12.5,0L0,12.5L12.4,25l2.3-2.2L6,14.1h19V10.9z"/%3E%3C/svg%3E') center no-repeat;
            filter: invert(1);

            @media(max-width: $responsive__max-width-mobile) {
                left: $spacing__outer-padding-mobile;
            }

            
            &:after {
                content: '';
            }
        }

        .swiper-button-next {
            color: $color__white;
            right: $spacing__outer-padding;
            background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"%3E%3Cpath d="M5.977,13.852H16.608l-4.9,4.9,1.269,1.225,7-7-7-7L11.752,7.2l4.856,4.9H5.977Z" transform="translate(-5.977 -5.977)"/%3E%3C/svg%3E') center no-repeat;
            filter: invert(1);

            @media(max-width: $responsive__max-width-mobile) {
                right: $spacing__outer-padding-mobile;
            }

            
            &:after {
                content: '';
            }
        }

        .close-lightbox {
            position: absolute;
            border: 0;
            padding: 0;
            right: $spacing__outer-padding;
            top: $spacing__outer-padding;
            z-index: 10;
            width: 25px;

            @media(max-width: $responsive__max-width-mobile) {
                right: $spacing__outer-padding-mobile;
            }

            svg {
                stroke: $color__white;
            }
        }
    }
}

@import "./credits-overlay";