.welcome-page {
    background: $color__white;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: auto;

    h1 {
        margin-left: $spacing__outer-padding;

        @media(max-width: $responsive__max-width-mobile) {
            margin-left: $spacing__outer-padding-mobile;
        }
    }

    .content { 
        display: flex;
        padding: 30px $spacing__outer-padding $spacing__outer-padding $spacing__outer-padding;
    
        @media(max-width: $responsive__max-width-mobile) {
            padding: 0 $spacing__outer-padding-mobile $spacing__outer-padding-mobile $spacing__outer-padding-mobile;
        }

        > :first-child {
            margin-right: $spacing__column-gap / 2;
            width: 66.666%;
        }

        > :last-child {
            margin-left: $spacing__column-gap / 2;
            width: 33.333%;
        }

        @media(max-width: $responsive__max-width-tablet) {
            flex-direction: column;

            > :first-child {
                margin-right: 0;
                margin-bottom: 50px;
                width: 100%;
            }
    
            > :last-child {
                margin-left: 0;
                width: 100%;
            }
        }
    }

    .button {
        width: 320px;
        text-align: left;
        margin-bottom: 25px;

        @media(max-width: $responsive__max-width-tablet) {
            max-width: 100%;
        }

        svg {
            margin-right: 10px;
            transition: all .1s;
        }

        &:hover {
            svg {
                fill: $color__white;
            }
        }

        &:first-child {
            &:hover {
                svg {
                    stroke: $color__white;
                    fill: none;
                }
            }
        }
    }

    .view-type {
        background-color: $color__gray;
        padding: 25px;
        margin-bottom: 25px;

        .icon {
            float: left;
            margin-right: 15px;
        }

        .h4 {
            font-weight: normal;
            margin: 0;
        }
    }

    .crosselling-product {
        max-width: 368px;

        @media(max-width: $responsive__max-width-mobile) {
            max-width: 100%;
        }

        img {
            width: 100%;
        }

        .placeholder {
            background-color: $color__gray;
            min-height: 215px;
            
            &.cover {
                aspect-ratio: 3 / 4;
                margin-bottom: 40px;
            }
        }
    }
}